
.Roulette-icon-container {
  position: fixed; /* Fixe le composant sur l'écran */
  bottom: 30%; /* Position par rapport au bas de l'écran */
  right: 2px; /* Position par rapport à la droite de l'écran */
  animation: move 5s infinite; /* Applique l'animation */
  z-index: 99999999; /* High z-index to make sure it's always visible */
}

.Roulette-icon {
  width: 80px; /* Adjust size */
  height: 80px;
}

/* Animation for bouncing effect */
@keyframes move {
  0% {
    transform: translateY(0); /* Initial position */
  }
  25% {
    transform: translateY(-15px); /* Move up */
  }
  50% {
    transform: translateY(0); /* Back to initial position */
  }
  75% {
    transform: translateY(15px); /* Move down */
  }
  100% {
    transform: translateY(0); /* Back to initial position */
  }
}