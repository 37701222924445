.button-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; /* Pour qu'il reste flexible et s'adapte à la taille de l'écran */
    margin-bottom: 20px;
   
  }
  
  .button-container button {
    background-color: var( --trri-color);
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button-container button:hover {
    background-color: var(--secondary-color);
  }
  
  .button-container button svg {
    margin-right: 10px; /* Espacement entre l'icône et le texte */
  }
  