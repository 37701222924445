.banner {
    
    color: #fff;
    text-align: center;
    padding: 2rem 1rem;
  }
  
  .banner h1 {
    font-size: 2rem;
  }
  
  .banner button {
    background-color: #fff;
    color: #0d47a1;
    border: none;
    padding: 1rem;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    margin: 2%;
  }
  