.team-container {
   text-align: center;
   background-color: rgba(6, 6, 6, 0.519);
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
    max-height: 80vh; /* Définit la hauteur maximale du formulaire */
  overflow-y: auto; /* Ajoute un défilement vertical si nécessaire */
    
  }
  
  .team-info {
    font-size: 18px;
    color: #ffffff;
  }
  
  .team-info p {
    margin: 10px 0; /* Espacement entre les lignes */
  }
  .tabs {
    display: flex;
    gap: 2px;
    justify-content: space-between;
    
    border: 1px solid;
    width: 100%;
    
  }
  
  .tabs button {
    max-width: 20%;
    margin: 0;
    cursor: pointer;
    background-color: #80636300;
    border: none;
    border-bottom: 2px solid transparent;
    transition: background-color 0.3s;
  }
  
  .tabs button.active {
    background-color: #966e01;
    color: white;
    
  }
  
  .tabs button:hover {
    background-color: #e0e0e0;
  }
  
  .tab-content {
    
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .team-info, .level-users {
    margin-top: 20px;
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  ul li {
    
    background-color: #f9f9f900;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  