.task-container {
    max-width: 800px; /* Limite la largeur maximale du conteneur */
    text-align: center;
    margin: 20px auto; /* Centre le conteneur horizontalement */
    padding: 20px; /* Ajoute du rembourrage à l'intérieur du conteneur */
    border: 1px solid #ddd; /* Bordure légère autour du conteneur */
    border-radius: 8px; /* Coins arrondis pour le conteneur */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre légère pour le conteneur */
   
}

h2 {
    text-align: center; /* Centre le titre */
    color: #fffdfd; /* Couleur du texte */
    margin-bottom: 20px; /* Espacement en bas du titre */
}

h3, h4 {
    color: #ffffff; /* Couleur du texte pour les sous-titres */
    margin: 10px 0; /* Espacement vertical autour des sous-titres */
}

button {
    display: inline-block; /* Pour que le bouton prenne la taille de son contenu */
    padding: 10px 20px; /* Ajoute du rembourrage autour du texte du bouton */
    margin: 10px; /* Espacement autour des boutons */
    border: none; /* Supprime la bordure par défaut */
    border-radius: 5px; /* Coins arrondis pour les boutons */
     background-color: var(--secondary-color); /* Couleur de fond du bouton */
    color: white; /* Couleur du texte */
    font-size: 16px; /* Taille de la police du texte */
    cursor: pointer; /* Change le curseur lorsque survolé */
    transition: background-color 0.3s; /* Animation pour le changement de couleur */
}

button:hover {
    background-color: #00b3aa; /* Couleur de fond lors du survol */
}

.investment-plans {
    margin-top: 30px; /* Espacement au-dessus de la section des plans d'investissement */
    border-top: 1px solid #ddd; /* Ligne de séparation */
    padding-top: 20px; /* Rembourrage supérieur */
}
