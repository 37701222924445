.information-section {
    padding: 2rem 1rem;
    background-color: var(--trri-color);
    border-radius: 10px;
    margin-top: 2rem;
  }
  
  .information-block {
    margin-bottom: 2rem;
  }
  
  .information-block h3 {
    color:var(--secondary-color);
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .information-block p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #ffffff;
  }
  