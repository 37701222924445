.footer1 {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: space-around; /* Espacement entre les éléments */
    align-items: center; /* Centre verticalement */
    padding: 10px 0; /* Espacement autour du footer */
     /* Couleur de fond du footer */
    border-top: 1px solid #e7e7e7; /* Bordure supérieure */
    background-color: black;
    border-radius: 10px;
    width: 100%;
  }
  
  .footer1-item {
    display: flex;
    flex-direction: column; /* Aligne les éléments en colonne */
    align-items: center; /* Centre les éléments horizontalement */
    color: #fdfdfd; /* Couleur du texte */
    text-align: center; /* Centre le texte */
  }
  
  .footer1-item img {
    width: 34px; /* Taille des icônes */
    height: 34px; /* Taille des icônes */
    margin-bottom: 5px; /* Espacement entre l'icône et le texte */
  }
  
  .footer1-item:hover {
    color: #00ff0d; /* Couleur au survol */
    cursor: pointer; /* Curseur de pointeur au survol */
  }
  