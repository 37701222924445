.signup-form {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2rem;
  background-color: rgba(6, 6, 6, 0.519);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 80vh; /* Sets the maximum height of the form */
  overflow-y: auto; /* Adds vertical scrolling if necessary */
}

.signup-form h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.signup-form label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.signup-form input {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.phone-input {
  display: flex;
}

.phone-input select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px 0 0 4px;
  height: 100%;
  width: 70px;
}

.phone-input input {
  flex: 1;
  border-radius: 0 4px 4px 0;
}

.signup-form button {
  padding: 0.75rem;
  background-color: var(--secondary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.signup-form button:hover {
  background-color: #01e933;
}
