/* Style général du conteneur d'administration */
.admin-container {
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  
}

/* Titre du conteneur */
.admin-container h2 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 20px;
  color: #333; /* Couleur plus sombre pour un meilleur contraste */
}

/* Styles des onglets */
.react-tabs__tab {
  font-size: 16px;
  padding: 10px 20px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.react-tabs__tab--selected {
  background-color: #4CAF50; /* Couleur verte pour l'onglet sélectionné */
  color: white;
}

/* Style du tableau */
.admin-table {
  width: 100%;
  border: 2px solid #ddd; /* Bordure générale du tableau */
  
  
  border-collapse: collapse; /* Supprime l'espacement entre les cellules */
  border-spacing: 0; /* Supprime l'espacement par défaut entre les cellules */
}

/* Styles des cellules de tableau */
.admin-table th, 
.admin-table td {
  padding: 2px 2px;
  border: 1px solid #ddd; /* Bordures fines pour les cellules */
  text-align: center;
  font-size: 16px;
  box-sizing: border-box; /* Assure que la taille des cellules inclut le padding */
}

/* Style des en-têtes de tableau */
.admin-table th {
  background-color: #4CAF50; /* Couleur verte pour les en-têtes */
  color: white;
}

/* Style des boutons dans le tableau */
.admin-table button {
  padding: 8px 16px;
  background-color: var(--secondary-color, #007BFF); /* Couleur secondaire avec fallback */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-right: 5px;
  transition: background-color 0.3s ease;
}

.admin-table button:hover {
  background-color: #0056b3; /* Couleur plus foncée au survol */
}

/* Style du bouton Supprimer */
.admin-table button:nth-child(3) {
  background-color: #dc3545; /* Rouge pour le bouton Supprimer */
}

.admin-table button:nth-child(3):hover {
  background-color: #c82333; /* Couleur rouge foncé au survol */
}

/* Styles pour le champ de recherche */
.admin-dashboard input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border 0.3s;
}

.admin-dashboard input[type="text"]:focus {
  border-color: #4CAF50; /* Bordure verte au focus */
  outline: none; /* Supprime le contour par défaut */
}
