.help-center {
    background-color: var( --trri-color);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    
  }
  
  .help-center h2 {
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .help-center ul {
    list-style-type: none;
    padding: 0;
  }
  
  .help-center li {
    margin-bottom: 20px;
  }
  
  .help-center li strong {
    font-size: 18px;
  }
  
  .help-center li p {
    font-size: 16px;
    color: #a7a7a7;
  }
  