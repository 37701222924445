.invite-friends-container {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-x: visible; /* Gardez ceci si vous avez des éléments qui pourraient dépasser */
  
  text-align: center;
  background-color: rgba(6, 6, 6, 0.519);
  
  /* Définir une largeur et une largeur maximale */
  width: 100%; /* Prend toute la largeur disponible */
  
  margin: 0 auto; /* Centre le conteneur */
}

.referral-link-container {
  display: flex; /* Utilisation de flex pour mieux contrôler les éléments internes */
  flex-direction: column; /* Organise les éléments en colonne */
  align-items: center; /* Centre les éléments horizontalement */
  width: 100%;
  padding: 20px;
}

.referral-link-container p {
  font-size: 18px;
  color: #ffffff;
  width: 100%; /* Prend toute la largeur disponible */
  max-width: 400px; /* Limite la largeur du paragraphe */
  word-wrap: break-word; /* Force les mots longs à passer à la ligne suivante */
  white-space: normal; /* Permet les sauts de ligne normaux */
  overflow-wrap: break-word; /* Assure que les longs mots se cassent à la fin de la ligne */
  line-height: 1.5; /* Espacement entre les lignes pour une meilleure lisibilité */
}

.referral-link-container button {
  margin: 10px 5px; /* Espacement entre les boutons */
  padding: 10px 15px;
  background-color: var(--secondary-color);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s; /* Pour un effet de transition fluide */
}

.referral-link-container button:hover {
  background-color: rgb(52, 228, 3); /* Couleur au survol */
}
