.profile-page{
  width: 100%;
  overflow-x: auto;
  max-height: 80vh; /* Définit la hauteur maximale du formulaire */
  overflow-y: auto; /* Ajoute un défilement vertical si nécessaire */
  
}
.profile-container {
    text-align: center;
    
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
   
    
    background-color: rgba(6, 6, 6, 0.519);
    width: 100%;
   
  }
  
  .profile-info {
    font-size: 15px;
    color: #ffffff;
  }
  
  .profile-info p {
    margin: 10px 0; /* Espacement entre les lignes */
    font-size: larger;
  }
  
   .important{
    font-size: 25px;
    animation: changeColors 5s infinite alternate;
    border-radius: 30%;
    background: rgb(63,18,122);
background: radial-gradient(circle, rgba(63,18,122,0.6867997198879552) 38%, rgba(0,249,3,0.6531862745098039) 99%);
  }
  @keyframes changeColors {
    0% {
        background: rgb(63, 18, 122);
        background: radial-gradient(circle, rgba(63, 18, 122, 0.6867997198879552) 38%, rgba(0, 249, 3, 0.6531862745098039) 99%);
    }
    50% {
        background: rgba(0, 249, 4, 0.503);
        background: radial-gradient(circle, rgba(0, 249, 4, 0.452) 38%, rgba(63, 18, 122, 0.6531862745098039) 99%);
    }
    100% {
        background: rgb(63, 18, 122);
        background: radial-gradient(circle, rgba(63, 18, 122, 0.6867997198879552) 38%, rgba(0, 249, 3, 0.6531862745098039) 99%);
    }
}
.history-container{
  padding: 5px;
}

.history-table {
  background-color: rgb(20, 11, 49);
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed; /* Fixe le modèle de mise en page */
}

.history-table th,
.history-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 10px;
}

.history-table th {
  background-color: #f2f2f200;
}



.history-item:nth-child(even) {
  background-color: #f9f9f900;
}