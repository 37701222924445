.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Make sure it overlays other elements */
  width: 100%;
  max-height: 100%;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
              rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
              rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
              rgba(0, 0, 0, 0.06) 0px 2px 1px,
              rgba(0, 0, 0, 0.09) 0px 4px 2px,
              rgba(0, 0, 0, 0.09) 0px 8px 4px,
              rgba(0, 0, 0, 0.09) 0px 16px 8px,
              rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.modal-content {
  background: #fff; /* Fallback color */
  padding: 20px;
  border-radius: 8px;
  width: 95%;
  max-height: 95%;
  position: relative;
  background-image: url('../assests/futuristic-robot-interacting-with-money.jpg'); /* Background image for modal */
  background-size: cover; /* Cover entire modal */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent repetition of background image */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add some shadow for effect */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
}

.close-button:hover {
  background-color: darkred;
}
