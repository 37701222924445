
  
   
  .spin-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .container {
    height: 350px;
    width: 350px;
    background: #ffd700;
    border: #ffd600 5px solid;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 40px rgb(95, 94, 94);
    transition: 3s all;
  }
  
  .container .segment {
    height: 50%;
    width: 15%;
    clip-path: polygon(100% 0, 50% 100%, 0 0);
    transform: translateX(-50%);
    transform-origin: bottom;
    position: absolute;
    left: 42.5%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: monospace;
    font-weight: bold;
    color: #ffffff;
    writing-mode: vertical-rl;
  }
  
  .container .segment-1 { background: #f50303; transform: rotate(0deg); }
  .container .segment-2 { background: #000000; transform: rotate(18deg); }
  .container .segment-3 { background: #f50303; transform: rotate(36deg); }
  .container .segment-4 { background: #000000; transform: rotate(54deg); }
  .container .segment-5 { background: #f50303; transform: rotate(72deg); }
  .container .segment-6 { background: #000000; transform: rotate(90deg); }
  .container .segment-7 { background: #f50303; transform: rotate(108deg); }
  .container .segment-8 { background: #000000; transform: rotate(126deg); }
  .container .segment-9 { background: #f50303; transform: rotate(144deg); }
  .container .segment-10 { background: #000000; transform: rotate(162deg); }
  .container .segment-11 { background: #f50303; transform: rotate(180deg); }
  .container .segment-12 { background: #000000; transform: rotate(198deg); }
  .container .segment-13 { background: #f50303; transform: rotate(216deg); }
  .container .segment-14 { background: #000000; transform: rotate(234deg); }
  .container .segment-15 { background: #f50303; transform: rotate(252deg); }
  .container .segment-16 { background: #000000; transform: rotate(270deg); }
  .container .segment-17 { background: #f50303; transform: rotate(288deg); }
  .container .segment-18 { background: #000000; transform: rotate(306deg); }
  .container .segment-19 { background: #f50303; transform: rotate(324deg); }
  .container .segment-20 { background: #000000; transform: rotate(342deg); }
  
  .mid {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    position: absolute;
    background: rgb(255,215,0);
    background: radial-gradient(circle, rgba(255,215,0,1) 14%, rgba(0,0,0,1) 48%);
    left: 50%;
    top: 37%;
    transform: translate(-50%, -50%);
  }
  
  #spin {
    height: 60px;
    width: 200px;
    background: #2f32e4;
    margin-top: 20px;
    font-size: 30px;
    color: white;
    font-weight: bold;
    letter-spacing: 4px;
    border: 1px solid white;
    cursor: pointer;
    box-shadow: 0 5px 10px gray;
    transition: 0.2s all;
  }
  
  #spin:hover {
    box-shadow: none;
  }
  
  
  
  .result {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    animation: fadeIn 0.5s ease-in-out; /* Fade-in animation */
    text-align: center; /* Center the text */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }