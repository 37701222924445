/* src/components/TelegramIcon.css */

.telegram-icon-container {
    position: fixed; /* Fixe le composant sur l'écran */
    bottom: 90px; /* Position par rapport au bas de l'écran */
    right: 20px; /* Position par rapport à la droite de l'écran */
    animation: move 5s infinite; /* Applique l'animation */
    z-index: 99999999;
  }
  
  .telegram-icon {
    width: 50px; /* Ajustez la taille de l'icône */
    height: 50px;
  }
  
  /* Définition de l'animation */
  @keyframes move {
    0% {
      transform: translateY(0); /* Position initiale */
    }
    25% {
      transform: translateY(-15px); /* Déplacement vers le haut */
    }
    50% {
      transform: translateY(0); /* Retour à la position initiale */
    }
    75% {
      transform: translateY(15px); /* Déplacement vers le bas */
    }
    100% {
      transform: translateY(0); /* Retour à la position initiale */
    }
  }
  