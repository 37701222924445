:root {
  --primary-color: #fff;
  --secondary-color: #0da13c;
  --trri-color: rgb(40 20 75 / 86%);
  --font-size-large: 2rem;
  --font-size-medium: 1.5rem;
  --font-size-small: 1rem;
  --border-angle: 0deg;
}

* {
  box-sizing: border-box; /* Assure que padding et border sont inclus dans la largeur/hauteur */
}

body, html {
  margin: 0;
  padding: 0; /* Assure qu'il n'y a pas de padding */
  width: 100%; /* Largeur complète */
  height: 100%; /* Hauteur complète */
  overflow-x: hidden; /* Évite le débordement horizontal */
  background-image: url('./assests/LSsT.gif');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Arial', sans-serif;
  background-color: var(--trri-color);
  color: var(--primary-color);
}

.box {
  width: 100%;
  display: grid;
  place-content: center;
  color: white;
  text-shadow: 0 1px 0 #000;

  --main-bg: conic-gradient(
    from var(--border-angle),
    #213,
    #112 5%,
    #112 60%,
    #213 95%
  );

  border: solid 5px transparent;
  border-radius: 2em;

  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #08f, #f03 99%, transparent);

  background: var(--gradient-border) border-box;
  background-position: center center;
  animation: bg-spin 3s linear infinite;
}

@keyframes bg-spin {
  to {
    --border-angle: 360deg;
  }
}

.box:hover {
  animation-play-state: paused;
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0deg;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Hauteur complète de la fenêtre */
  font-size: 24px; /* Ajuster la taille de la police */
  color: #ffffff; /* Couleur du texte du loader */
}
