.deposit-container {
    
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;
    text-align: center;
    background-color: rgba(6, 6, 6, 0.519);
   
  }
  
  .deposit-container h2 {
    margin-bottom: 20px;
    
  }
  
  .deposit-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .deposit-container label {
    margin-bottom: 10px;
  }
  
  .deposit-container input {
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
    max-width: 300px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .deposit-container button {
    padding: 10px 20px;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .deposit-container button:hover {
    background-color: #06ff44;
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
  }
  