.how-it-works {
    padding: 2rem 1rem;
    text-align: center;
  }
  
  .how-it-works .steps-container {
    display: flex;
    justify-content: space-around;
    margin-top: 1.5rem;
  }
  
  .how-it-works .step {
    padding: 1rem;
    border: 1px solid #ddd;
    background-color:var(--trri-color);
    border-radius: 5px;
    width: 30%;
    text-align: center;
    position: relative; /* Nécessaire pour l'animation de background */
    background-image: linear-gradient(90deg, var(--trri-color), rgba(232, 6, 6, 0)); /* Dégradé initial */
    background-size: 200% 100%; /* Double largeur pour animer */
    transition: background-position 0.5s ease-in-out; /* Transition pour lisser l'animation */
  }
  .how-it-works .step:hover{
    background-position: -100% 0; /* Animation de droite à gauche */
    background-color: blueviolet;
  }