.rules-container {
  background-color: #2b022e; /* Light background for readability */
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 20px; /* Padding for the content */
  max-height: 400px; /* Set a maximum height for the container */
  overflow-y: auto; /* Add vertical scroll when content exceeds max-height */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-size: 15px;
}
