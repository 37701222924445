/* Reset de base */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #fcfcfc;
  --secondary-color: #3498DB;
  --accent-color: #E74C3C;
  --text-light: #ECF0F1;
  --text-dark: #2C3E50;
  --gradient-start: #2980B9;
  --gradient-end: #2C3E50;
  --primary-blue: #0a1929;
  --primary-green: #00ff9d;
  --accent-yellow: #ffd700;
  --text-gray: #a0aec0;
}

/* Contexte général */
body, html {
  height: 100%;
  font-family: 'Poppins', sans-serif;
  background-color: #f5f6fa;
}

.cont {
  width: 100%;
  min-height: 100vh;
  padding: 2rem 4rem;
  background: radial-gradient(circle at center, #0a2955 0%, var(--primary-blue) 100%);
  display: flex;
  justify-content: center;
}

/* Contenu de bienvenue */
.welcome-container {
  width: 100%;
  max-width: 1200px;
  background: linear-gradient(135deg, var(--gradient-start) 0%, var(--gradient-end) 100%);
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.welcome-content {
  color: var(--text-light);
}

/* Section de l'en-tête */
.header-section {
  text-align: center;
  margin-bottom: 4rem;
}

.logo-container {
  margin-bottom: 2rem;
}

.logo {
  font-size: 3rem;
  font-weight: bold;
  background: white;
  color: var(--primary-color);
  width: 80px;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.welcome-title {
  font-size: 2.5rem;
  font-weight: 700;
}

.welcome-subtitle {
  font-size: 1.2rem;
  opacity: 0.9;
}

/* Carte du gestionnaire */
.manager-section {
  margin-bottom: 4rem;
}

.manager-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.manager-avatar {
  width: 100px;
  height: 100px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.manager-info h2 {
  margin-bottom: 0.5rem;
}

.manager-id {
  opacity: 0.8;
  font-family: monospace;
  font-size: 0.9rem;
}

/* Boutons de contact */
.contact-buttons {
  display: flex;
  gap: 1rem;
}

.telegram-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: white;
  color: var(--primary-color);
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.telegram-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.telegram-icon {
  width: 24px;
  height: 24px;
  fill: #0088cc;
}

/* Section des fonctionnalités */
.features-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 4rem;
}

.feature {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease;
}

.feature:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* Section d'animation Bitcoin */
.bitcoin-animation {
  width: 50%;
  position: relative;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-container {
  position: relative;
  width: 300px;
  height: 300px;
}

.circle {
  position: absolute;
  border-radius: 50%;
  border: 2px dashed;
  animation: rotate 20s linear infinite;
}

.circle-outer {
  width: 100%;
  height: 100%;
  border-color: var(--primary-green);
}

.circle-inner {
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  border-color: #0088ff;
  animation-direction: reverse;
}

.bitcoin-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  background: linear-gradient(45deg, var(--accent-yellow), #ff8c00);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  font-weight: bold;
}

.dot {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--primary-green);
  border-radius: 50%;
  animation: pulse 2s ease-in-out infinite;
}

.dot1 { top: 0; right: 20%; }
.dot2 { bottom: 20%; right: 0; animation-delay: 0.6s; }
.dot3 { top: 20%; left: 0; animation-delay: 1.2s; }

.circuit-lines {
  position: absolute;
  inset: 0;
  opacity: 0.2;
}

.line {
  position: absolute;
  background: linear-gradient(90deg, #0088ff, var(--primary-green));
}

.line1, .line2 { height: 2px; width: 100%; }
.line1 { top: 25%; }
.line2 { top: 75%; }
.line3, .line4 { width: 2px; height: 100%; }
.line3 { left: 25%; }
.line4 { left: 75%; }

/* Animations */
@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.5); opacity: 0.5; }
  100% { transform: scale(1); opacity: 1; }
}

/* Media Queries */
@media (max-width: 768px) {
  .manager-card { flex-direction: column; text-align: center; padding: 1.5rem; }
  .welcome-title { font-size: 2rem; }
  .features-section { grid-template-columns: 1fr; }
  .contact-buttons { justify-content: center; }
}
