.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: black;
  color: white;
  
  
 
}

.header h1 {
  margin: 0;
  font-size: 24px;
}

.header-buttons {
  display: flex;
  gap: 10px;
}

.header-buttons button {
  padding: 5px 10px;
  background-color: #280a46;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.header-buttons button:hover {
  background-color: #45a049;
}

.header-buttons button:active {
  background-color: #39843a;
}
