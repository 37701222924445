.investment-plans {
    padding: 2rem 1rem;
    
    text-align: center;
    width: 100%;
  }
  
  .plans-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 1rem;
  }
  
  .plan {
    background-color: var(--trri-color);
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 1rem;
    width: 200px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative; /* Nécessaire pour l'animation de background */
    overflow: hidden;
    background-image: linear-gradient(90deg, var(--trri-color), rgba(232, 6, 6, 0)); /* Dégradé initial */
    background-size: 200% 100%; /* Double largeur pour animer */
    transition: background-position 0.5s ease-in-out; /* Transition pour lisser l'animation */
  }
  
  .plan:hover {
    background-position: -100% 0; /* Animation de droite à gauche */
    background-color: blueviolet;
  }
  
  .plan h4 {
    font-size: 1.25rem;
    
  }
  