.video-animation img {
    width: 100%;
    height: 150px; /* Hauteur maximale */
    object-fit: cover; /* Pour que la vidéo reste dans les proportions même avec une hauteur fixe */
    border-radius: 10px;
   
  }
  
  .video-animation {
    margin-bottom: 20px; 
    width: 100%;
    border-radius: 10px;
  }
  