/* src/styles/CryptoPricesNews.css */
.crypto-prices-news {
   text-align: center;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    
  }
  
  .crypto-prices-news h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  #gecko-widget {
    margin-top: 20px;
    width: 100%;
  }
  