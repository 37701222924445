.bonus-section {
    padding: 2rem 1rem;
    background-color: var(--trri-color);
    text-align: center;
    border-radius: 10px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .bonus-list {
    list-style-type: none;
    padding: 0;
  }
  
  .bonus-list li {
    margin: 0.5rem 0;
    font-size: 1.1rem;
  }
  
  .bonus-list li strong {
    color: var(--secondary-color);
  }
  