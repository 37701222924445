.login-form {
    display: flex;
    flex-direction: column;
   width: 100%;
    margin: 0 auto;
    padding: 2rem;
    background-color: rgba(6, 6, 6, 0.519);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #ffffff;
  }
  
  .login-form label {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .login-form input {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .login-form button {
    padding: 0.75rem;
    background-color:var(--secondary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .login-form button:hover {
    background-color: #01e933;
  }
  