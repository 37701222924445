.pyramid-investment {
    background-color: var(--trri-color);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    
    margin: 0 auto;
    text-align: center;
    
  }
  
  .pyramid-investment h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .pyramid-investment p {
    font-size: 18px;
    color: #cac9c9;
  }
  
  .levels-container {
    margin-top: 20px;
  }
  
  .level {
    background-color: #ffffffc5;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  }
  
  .level h3 {
    font-size: 22px;
    color: #007bff;
  }
  
  .level p {
    font-size: 18px;
    color: #333;
  }
  
  .level strong {
    color: #28a745; /* Vert pour mettre en valeur les pourcentages */
  }
  