.withdraw-container {
   
    padding: 25px;
    border-radius: 12px; /* Coins plus arrondis */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Ombre subtile */
    max-width: 450px; /* Largeur maximale */
    margin: 30px auto; /* Centré avec un espacement supérieur et inférieur */
    text-align: center;
    border: none; /* Bordure légère pour le conteneur */
    background-color: rgba(6, 6, 6, 0.519);
  }
  
  .withdraw-container h2 {
    font-size: 24px; /* Police plus grande pour le titre */
    margin-bottom: 20px;
    color: #ffffff; /* Couleur de texte plus foncée */
  }
  
  .withdraw-container label {
    display: block; /* Les labels sont en bloc pour aligner au-dessus des inputs */
    font-size: 16px;
    margin-bottom: 8px;
    text-align: left; /* Aligner à gauche pour une meilleure lisibilité */
    color: #fffafa;
    font-weight: 500; /* Un peu plus gras pour les labels */
  }
  
  .withdraw-container input {
    padding: 12px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 6px; /* Bords arrondis pour les inputs */
    font-size: 16px;
    color: #333;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05); /* Ombre interne légère */
    transition: border-color 0.2s;
  }
  
  .withdraw-container input:focus {
    border-color: var(--secondary-color); /* Bordure bleue sur le focus */
    outline: none; /* Retirer l'outline par défaut */
  }
  
  .withdraw-container button {
    padding: 12px 20px;
    background-color: var(--secondary-color); /* Couleur bleue pour le bouton */
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .withdraw-container button:hover {
    background-color: #37ff00; /* Changement de couleur au survol */
    transform: translateY(-2px); /* Effet de levée légère */
  }
  
  .withdraw-container button:active {
    transform: translateY(0); /* Réinitialise l'effet lors du clic */
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-bottom: 20px;
    background-color: #ffe5e5;
    padding: 10px;
    border-radius: 6px; /* Un style d'erreur avec fond rouge clair */
    border: 1px solid #ffb3b3;
  }
  